import React from 'react';


const Home = () => {

 return (
   <div>
     <h1 className="text-green-800 text-4xl">Welcome to the Homepage</h1>
   </div>
 );
 
};

export default Home;
