import React, { useState } from 'react';
import axios from 'axios';
import keycloak from "../Keycloak"

let axiosInstance = axios.create();

// let axiosInstance = axios.create({
//   baseURL: 'http://localhost:3001'
// });



axiosInstance.interceptors.request.use(
  config => {
    const token = keycloak.token;
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use((response)=>{
  return response;
}, function(error) {
  Promise.reject(error);
});

const url = process.env.REACT_APP_API_URL || "/api";
const commitSha = process.env.REACT_APP_COMMIT_SHA;
const commitUrl = "https://repo.bespin.cce.af.mil/bespin/enterprise-services/cloudops/licensed/libraries/blueprints/web-app-template/-/commit/" + commitSha;

const Secured = () => {

  const click = () => {
    axiosInstance({url: url})
    .then(data => console.log(data))
    .catch(err => console.log(err));
  }

  const [secret, setSecret] = useState('loading...') 

  axiosInstance.get('/api/secret').then((response) => {
    
    if (response.data.SecretString.length > 0) {
      setSecret(response.data.SecretString);
    } else {
      setSecret(`No Secret Found, ${response.data.SecretString}`);
    }
  });

 return (
   <div>
     <h1 className="text-black text-4xl">Welcome to the Protected Page.</h1>
     The last commit is <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href={commitUrl}>{commitSha}</a>.<br/>
     <div>
       This is an example of a secret:{secret}
     </div>
     <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          target="_blank"
          rel="noopener noreferrer"
          onClick={click}
      >
          Test (Check Console)
      </button>
   </div>
 );
};

export default Secured;
