import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import WelcomePage from "./pages/Home";
import SecuredPage from "./pages/Secure";
import PrivateRoute from "./helpers/PrivateRoute";

function App() {
  return (
    <div>
        <Nav />
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<WelcomePage />} />
            <Route
              path="/secured"
              element={
                 <PrivateRoute>
                  <SecuredPage />
                 </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
    </div>
  );
 }
 
 export default App;
